import {
  GmBasket,
  MenuItemsPaged,
  QueryGmBasketArgs,
  TagsPaged,
  Translation,
} from '@/generated/graphql'
import possibleTypesIntrospection from '@/generated/possible-types'
import { FieldPolicy, FieldReadFunction, InMemoryCacheConfig } from '@apollo/client'
import { getTranslationForField } from './translationHelper'

function fieldPolicy(): FieldPolicy<MenuItemsPaged> {
  return {
    keyArgs: ['filters'],
    merge(existing, incoming, { args }) {
      if (!args?.offset || args.offset === 0) return incoming
      return {
        ...existing,
        ...incoming,
        items: [...(existing?.items || []), ...(incoming?.items || [])],
      }
    },
  }
}

const getFieldPolicy =
  (fieldName: string): FieldReadFunction<any> =>
  (existing, { readField }) => {
    const translations: readonly Translation[] = readField('translations') || []
    return getTranslationForField(fieldName, existing, [...translations])
  }

const Vendor = {
  fields: {
    liveMenuItems: fieldPolicy(),
    liveMenuOptions: fieldPolicy(),
    draftMenuItems: fieldPolicy(),
    draftMenuOptions: fieldPolicy(),
    descriptionShort: getFieldPolicy('descriptionShort'),
    descriptionFull: getFieldPolicy('descriptionFull'),
    quote: getFieldPolicy('quote'),
    metaTitle: getFieldPolicy('metaTitle'),
    metaDescription: getFieldPolicy('metaDescription'),
    sourcing: getFieldPolicy('sourcing'),
    rating: {
      read: (existing: number | null) => {
        const ratingValue = existing ?? 0
        return Math.round(ratingValue) === ratingValue
          ? ratingValue
          : Number(ratingValue.toFixed(1))
      },
    },
  },
}

const cache: InMemoryCacheConfig = {
  possibleTypes: possibleTypesIntrospection.possibleTypes,
  typePolicies: {
    Vendor,
    GmAvailability: {
      fields: {
        itemsPagedv2: fieldPolicy(),
      },
    },
    CCVendorMenuItems: {
      fields: {
        items: {
          keyArgs: ['limit', 'offset', 'filters'],
          merge: (
            existing: Array<{ __ref: string }> | undefined,
            incoming: Array<{ __ref: string }> | undefined,
            { variables },
          ) => {
            if (!variables?.offset || variables.offset === 0) return incoming
            const existingItems = existing ?? []
            const incomingItems = incoming ?? []
            return [...existingItems, ...incomingItems]
          },
        },
      },
    },
    MenuItem: {
      fields: {
        _id: (existing) => {
          return existing.replace('d-', '')
        },
        name: (existing, { readField }) => {
          const translations: readonly Translation[] = readField('translations') || []
          return getTranslationForField('name', existing, [...translations])
        },
        shortDescription: (existing, { readField }) => {
          const translations: readonly Translation[] = readField('translations') || []
          return getTranslationForField('shortDescription', existing, [...translations])
        },
        description: (existing, { readField }) => {
          const translations: readonly Translation[] = readField('translations') || []
          return getTranslationForField('description', existing, [...translations])
        },
      },
    },
    Query: {
      fields: {
        tags: {
          keyArgs: ['filters'],
          merge: (existing: TagsPaged, incoming: TagsPaged) => {
            return {
              ...existing,
              ...incoming,
              rows: [...(existing?.rows || []), ...(incoming?.rows || [])],
            }
          },
        },
        gmBasket: {
          read: (existing: unknown, { args, toReference }) => {
            if (existing) return existing
            const __typename: NonNullable<GmBasket['__typename']> = 'GmBasket'
            const { id } = args as QueryGmBasketArgs
            return toReference({ __typename, id })
          },
        },
      },
    },
    GmBasket: {
      fields: {
        items: {
          merge: (_existing, incoming: unknown[]) => incoming,
        },
      },
    },
  },
}

export default cache
